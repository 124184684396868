import React, { useState, useEffect } from 'react';

const TextTypingComponent = () => {
  
  const [text, setText] = useState('philosophy:~$ ');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const typingDelay = 50; // Delay between typing each character
  const deletingDelay = 20; // Delay between deleting each character
  const phraseDelay = 200; // Delay before starting to delete the current phrase

  useEffect(() => {

    const phrases = [
      'i didn\'t wanna do 9-5 ',
      'i decided to do computer science ',
      'now i work 24/7  ',
    ];

    let timeoutId: ReturnType<typeof setTimeout>;

    const typeText = () => {
      const currentPhrase = phrases[currentIndex];
      const currentText = text.slice(3);

      if (isDeleting) {
        // Deleting mode
        if (currentText.length === 0) {
          // Finished deleting, move to the next phrase
          setIsDeleting(false);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % phrases.length);
          timeoutId = setTimeout(typeText, phraseDelay);
        } else {
          // Delete one character
          setText(`~$ ${currentText.slice(0, -1)}`);
          timeoutId = setTimeout(typeText, deletingDelay);
        }
      } else {
        // Typing mode
        if (currentText.length === currentPhrase.length) {
          // Finished typing, start deleting
          setIsDeleting(true);
          timeoutId = setTimeout(typeText, phraseDelay);
        } else {
          // Type one character
          setText(`~$ ${currentPhrase.slice(0, currentText.length + 1)}`);
          timeoutId = setTimeout(typeText, typingDelay);
        }
      }
    };

    // Start typing
    timeoutId = setTimeout(typeText, typingDelay);

    return () => clearTimeout(timeoutId);
  }, [text, currentIndex, isDeleting]);

  return (
    <div>
      <h1>{text}</h1>
    </div>
  );
};

export default TextTypingComponent;
