import styles from './Footer.module.scss'

const Footer = () => {
    return(
        <footer className={styles.footer}>
            <div className={styles.leftSide}>
                If you think I’d be a good fit for your<br /> team,&nbsp;
                <a className={styles.link} href="mailto:hr.65@icloud.com">drop me a line</a> and let’s meet.
            </div>
            {/* <div className={styles.rightSide}>
                Contact buttons
            </div> */}
        </footer>
    )
}

export default Footer;