import styles from './Header.module.scss'
import linkedinIcon from '../../assets/icons/linkedin.svg'

const Header = () => {
    return (
        <div>
            <header className={styles.header}>
                <div className={styles.headerContent}>
                    <div className={styles.leftSide}>
                        Hamza Rasheed
                    </div>
                    <div className={styles.rightSide}>
                        {/* <div className={styles.iconContainer}> */}
                            <a href="https://www.linkedin.com/in/hamzarasheed8/">
                                <img src={linkedinIcon} alt="LinkedIn" />
                            </a>
                        {/* </div> */}
                    </div>
                </div>
            </header>
            <div className={styles.space}>
            </div>
        </div>
    )
}

export default Header;