import styles from './Main.module.scss'
import Typing from './TextTyping'

const Main = () => {
    return(
        <div className={styles.main}>

            <div className={styles.profile}>
                I am a dedicated and ambitious <b>Software Engineer</b> currently 
                pursuing a BSc (Honours) in <b>Computer Science</b> at the 
                <b> University of St Andrews</b>. With a passion 
                for programming and a drive for innovation, I have 
                honed my skills in various technologies, including 
                <b> Java</b>, <b>Python</b>, <b>C</b>, <b>JavaScript</b>, 
                <b> HTML</b>, <b>SQL</b>, <b>Git</b>, and <b>LaTeX</b>. 
                I am constantly seeking opportunities to apply my 
                knowledge and contribute to cutting-edge projects in the tech industry.
            </div>

            <div className={styles.info}>
                <div className={styles.section}>
                    Area of expertise
                </div>
                <div className={styles.list}>
                    <ul>
                        <li>Software Development</li>
                        <li>Data Analysis and Visualization</li>
                        <li>Project Management and Teamwork</li>
                    </ul>
                </div>
            </div>

            <div className={styles.info}>
                <div className={styles.section}>
                    Work Experience
                </div>
                <div className={styles.list}>
                    <ul>
                        <li>M&G Technology Internship</li>
                        <li>Featurespace Software Engineer Internship</li>
                        <li>Jane Street IN FOCUS</li>
                        <li>Optiver Technology Insight</li>
                        <li>JP Morgan Early Insight Into Technology</li>
                    </ul>
                </div>
            </div>

            <div className={styles.info}>
                <div className={styles.section}>
                    Recent Projects
                </div>
                <div className={styles.list}>
                    <ul>
                        <li>A web application for creating and solving sudoku puzzles</li>
                        <li>User space scheduler which executes an arbitrary list of programs</li>
                        <li>Developed the board game Royal Game of Ur</li>
                        <li>Analysed and refined more than 70,500 tweets on Rosetta’s Philae comet</li>
                    </ul>
                </div>
            </div>

            <div className={styles.info}>
                <div className={styles.section}>
                    Philosophy
                </div>
                <div className={styles.list}>
                    <Typing/>
                </div>
            </div>
                    
        </div>
    )
}

export default Main;